import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li><NavLink to='/' exact>Home</NavLink></li>
                    <li><NavLink to="/services">services</NavLink></li>
                    <li><NavLink to="/portfolio">Portfolio</NavLink></li>
                    {/* <li><NavLink to="/faq">FAQs</NavLink></li> */}
                    <li><NavLink to='/about_us'>About Us</NavLink></li>
                    <li><NavLink to='/contact_us'>Contact Us</NavLink></li>
                    {/* <li className="menu-hasdropdown"><a>services</a>
                        <ul className="menu-dropdown">
                            <li><a href={process.env.PUBLIC_URL + '/it_consultancy'}>IT Consultancy</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/digital_services'}>Digital Services</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/advisory_services'}>Advisory Services</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/data_structuring'}>Data Structuring</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/experience_design'}>Experience Design</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/content_engineering'}>Content Engineering</a></li>
                        </ul>
                    </li>
                    <li><a href={process.env.PUBLIC_URL + '/project_style1'}>Portfolio</a></li>
                    <li><a href={process.env.PUBLIC_URL + '/Faq'}>FAQs</a></li>
                    <li><a href={process.env.PUBLIC_URL + '/aboutus_02'}>About Us</a></li>
                    <li><a href={process.env.PUBLIC_URL + '/ContactUs'}>Contact Us</a></li> */}
                </ul>
            </nav>

        )
    }
}