import React, { Component } from 'react'
import { Widget } from '@typeform/embed-react'

export class ContactUsForm extends Component {
    render() {
        return (
            <Widget id="ZW4gqbM6" style={{ width: '100%' }} className="contact-form" />
        )
    }
}
export default ContactUsForm;