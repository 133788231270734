import React, { Component } from 'react';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FadeIn from 'react-fade-in';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home_banner';
import Lottie from "react-lottie";
import animation from './celebration-lottie.json'
import 'react-image-lightbox/style.css';
import $ from 'jquery';

const images = [
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg'
];

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    setTimeout(() => {
      $(".lottie-div").css("z-index", 1);
      $(".lottie-div").css("display", 'none');
    }, 7000);
  }
  render() {

    const { photoIndex, isOpen } = this.state;
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [{

        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {

        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {

        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    };

    // const defaultOptions = {
    //   loop: true,
    //   autoplay: true,
    //   animationData: animation,
    //   // rendererSettings: {
    //   //   preserveAspectRatio: "xMidYMid meet",
    //   // },
    // };


    return (
      <div className="site-main">
        {/* <div className='lottie-div'>
          <Lottie options={defaultOptions} height={800} width={1500} isClickToPauseDisabled={true} />
        </div> */}
        <Header />
        {/* home banner */}
        <CarouselPage />{/* home banner end*/}
        <section className="main-background">
          {/* service-section */}
          <section className="bg-img-about-us ttm-row aboutus-section-style2 clearfix">
            <div className="container-xl">
              <div className="row no-gutters align-items-center">{/* row */}
                <div className="col-lg-6 res-991-text-center res-991-mb-40">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper">
                    <img className="img-fluid" src="../images/home_sec_2.png" title="single-img-two" alt="single-img-two" />
                  </div>{/* ttm_single_image-wrapper end */}
                </div>
                <div className="col-lg-6">
                  <div className="spacing-4 ttm-bgcolor-skincolor">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5 className="font-dark">About Virtual Caim</h5>
                        <h2 className="title font-dark">We deal with security aspects of professional <br /> <span className='font-dark'>IT Services</span></h2>
                      </div>
                      <div className="title-desc font-dark">
                        <p className='font-dark'>We Listen. We Advise. We Secure. We Build, together. Happy clients & ongoing long-term relationships are what we strive for. Success is measured by results, the most important being how our clients feel about their experience with us.</p>
                      </div>
                    </div>{/* section title end */}
                    {/* row */}
                    <div className="row no-gutters mt-20">
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        {/*  featured-icon-box */}
                        <div className="featured-icon-box style3 left-icon icon-align-top">
                          <div className="featured-icon">{/*  featured-icon */}
                            <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                              <i className="ti ti-bookmark-alt font-dark" />{/*  ttm-icon */}
                            </div>
                          </div>
                          <div className="featured-content">{/*  featured-content */}
                            <div className="featured-title">{/*  featured-title */}
                              <h5 className='font-dark'>Certified Company</h5>
                            </div>
                            <div className="featured-desc">{/*  featured-desc */}
                              <p className='font-dark'>Virtual Caim is an ISO27001 Certified Organization.</p>
                            </div>
                          </div>
                        </div>{/*  featured-icon-box END */}
                      </div>
                    </div>{/* row END*/}
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}

                  </div>
                </div>
              </div>
              {/* row end */}
              {/* row */}
              <div className="row">
              </div>{/* row end*/}
            </div>
          </section>
          <section className="bg-img-trusted-by ttm-row bottomzero-padding-section position-relative z-1 clearfix">
            <div className="container-xl">
              <div className="row no-gutters">
                <div className="col-lg-10 mb_80 res-991-mb-0">
                  <div className="ttm-bgcolor-skincolor ttm-textcolor-white">
                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                      <div className="ttm-bg-layer-inner"></div>
                    </div>
                    <div className="layer-content">
                      <div className="spacing-5">
                        {/* section title */}
                        <div className="section-title mb-4 clearfix">
                          <div className="title-header">
                            <h5 className='font-dark'>About Virtual Caim</h5>
                            <h2 className="title font-dark">Devoted by 150+ <span className='font-dark'>Happy Clients</span></h2>
                          </div>
                          <div className="title-desc font-dark">
                            <p className='font-dark'>We Provide Outsourced IT Services For Small & Mid-Sized Business</p>
                          </div>
                        </div>{/* section title end */}
                        <div className="row pt-3">
                          <div className="col-md-4">
                            {/*  featured-icon-box */}
                            <div className="featured-icon-box style4 left-icon">
                              <div className="featured-icon">{/*  featured-icon */}
                                <div className="ttm-icon ttm-icon_element-size-md">
                                  <img className="img-fluid" src="../../images/icons/100Satisfaction.svg" alt="image" />
                                </div>
                              </div>
                              <div className="featured-content">{/*  featured-content */}
                                <div className="featured-title">{/*  featured-title */}
                                  <h5 className="fw-500 font-dark">100% Satisfaction</h5>
                                </div>
                              </div>
                            </div>{/*  featured-icon-box END */}
                          </div>
                          <div className="col-md-4">
                            {/*  featured-icon-box */}
                            <div className="featured-icon-box style4 left-icon">
                              <div className="featured-icon">{/*  featured-icon */}
                                <div className="ttm-icon ttm-icon_element-size-md">
                                  <img className="img-fluid" src="../../images/icons/WorldClassTeam.svg" alt="image" />
                                </div>
                              </div>
                              <div className="featured-content">{/*  featured-content */}
                                <div className="featured-title">{/*  featured-title */}
                                  <h5 className="fw-500 font-dark">World Class Team</h5>
                                </div>
                              </div>
                            </div>{/*  featured-icon-box END */}
                          </div>
                          <div className="col-md-4">
                            {/*  featured-icon-box */}
                            <div className="featured-icon-box style4 left-icon">
                              <div className="featured-icon">{/*  featured-icon */}
                                <div className="ttm-icon ttm-icon_element-size-md">
                                  <img className="img-fluid" src="../../images/icons/ExcellentAdvisors.svg" alt="image" />
                                </div>
                              </div>
                              <div className="featured-content">{/*  featured-content */}
                                <div className="featured-title">{/*  featured-title */}
                                  <h5 className="fw-500 font-dark">Excellent Advisors</h5>
                                </div>
                              </div>
                            </div>{/*  featured-icon-box END */}
                          </div>
                        </div>
                        <div className="row ttm-fid-row-wrapper">
                          <div className="col-md-4 col-sm-4">
                            {/*ttm-fid*/}
                            <div className="ttm-fid inside ttm-fid-view-lefticon style1">
                              <div className="ttm-fid-left">{/*ttm-fid-left*/}
                                <div className="ttm-fid-icon-wrapper mt-0">
                                  <img className="img-fluid width-37" src="../../images/icons/HappyClients.svg" alt="image" />
                                </div>
                              </div>
                              <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                <h4 className="ttm-fid-inner font-dark">
                                  <CountUp start={0} end={150} duration={10} />+
                                </h4>
                                <h3 className="ttm-fid-title  font-dark">Happy Clients</h3>{/*ttm-fid-title*/}
                              </div>
                            </div>{/* ttm-fid end*/}
                          </div>
                          <div className="col-md-4 col-sm-4">
                            {/*ttm-fid*/}
                            <div className="ttm-fid inside ttm-fid-view-lefticon style1">
                              <div className="ttm-fid-left">
                                <div className="ttm-fid-icon-wrapper mt-0">
                                  <img className="img-fluid width-37" src="../../images/icons/ProjectsCompleted.svg" alt="image" />
                                </div>
                              </div>
                              <div className="ttm-fid-contents text-left">
                                <h4 className="ttm-fid-inner  font-dark">
                                  <CountUp start={0} end={250} duration={10} />+
                                </h4>
                                <h3 className="ttm-fid-title  font-dark">Projects Completed</h3>
                              </div>
                            </div>{/* ttm-fid end*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service section   */}
          <section className="bg-img-our-services ttm-row services-section ttm-textcolor-white ttm-bg ttm-bgimage-yes clearfix">
            <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
            <div className="container-xl">
              <div className="row">{/* row */}
                <div className="col-lg-6 offset-lg-3">
                  {/* section title */}
                  <div className="section-title with-desc text-center clearfix">
                    <div className="title-header">
                      <h5>Our Services</h5>
                      <h2 className="title">We run all kinds of IT security services with extensive years of <span className="ttm-textcolor-white font-primary">experience</span></h2>
                    </div>
                  </div>{/* section title end */}
                </div>
              </div>
              {/* row end */}
              {/* row */}
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style5 text-left res-767-mb-20 backgroud-primary">
                    <div className="featured-icon">{/* featured-icon*/}
                      <div className="ttm-icon ttm-icon_element-size-lg">
                        <img className="img-fluid width-37" src="../../images/icons/Web2Security.svg" alt="image" />
                      </div>
                    </div>{/* featured-icon */}
                    <div className="featured-content">{/* featured-content */}
                      <div className="featured-title">
                        <h5 className='font-dark'>Web 2 Security</h5>{/* featured-title */}
                      </div>
                      <div className="featured-desc">{/* featured-description */}
                        <p className='font-dark'>We ensure the security of your organization for a basic Website/Application Testing to Network Infrastructure Security Audit.</p>
                      </div>
                      <a target="_blank" href='/portfolio' className="ttm-btn ttm-btn-size-sm btn-inline mt-3 font-dark">Read More <i className="ti ti-angle-double-right font-dark" /></a>
                    </div>{/* featured-content END*/}
                  </div>{/* featured-icon-box */}
                </div>
                <div className="col-lg-6 col-md-6">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style5 text-left res-767-mb-20 backgroud-primary">
                    <div className="featured-icon">{/* featured-icon*/}
                      <div className="ttm-icon ttm-icon_element-size-lg">
                        <img className="img-fluid width-37" src="../../images/icons/Web3Security.svg" alt="image" />
                      </div>
                    </div>{/* featured-icon */}
                    <div className="featured-content">{/* featured-content */}
                      <div className="featured-title">
                        <h5 className='font-dark'>Web 3 Security</h5>{/* featured-title */}
                      </div>
                      <div className="featured-desc">{/* featured-description */}
                        <p className='font-dark'>We tend to collaborate from the the basics to provide the best and complete security of your Blockchain Project.</p>
                      </div>
                      <a  target="_blank" href='/portfolio' className="ttm-btn ttm-btn-size-sm btn-inline mt-3 font-dark">Read More <i className="ti ti-angle-double-right font-dark" /></a>
                    </div>{/* featured-content END*/}
                  </div>{/* featured-icon-box */}
                </div>
              </div>{/* row end*/}
            </div>
          </section>
          {/* service section end  */}
          {/* tab section */}
          <section className="bg-img-milestone ttm-row tab-section clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-md-12">
                  {/* section title */}
                  <div className="section-title text-center with-desc clearfix">
                    <div className="title-header">
                      <h5>What We Offer For You</h5>
                      <h2 className="title">Here are top <span className='font-primary'>Services </span></h2>
                    </div>
                  </div>{/* section title end */}
                </div>
              </div>
              {/* row end */}
              {/* row */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="ttm-tabs text-center ttm-tab-style-classic style1">
                    <Tabs defaultIndex={0} onSelect={index => console.log(index)}>{/* tabs */}
                      <TabList className="tabs">
                        <Tab className="tab" title="Web/Mobile Security"><span><i className="flaticon flaticon-computer" /> Web/Mobile Security</span></Tab>
                        <Tab className="tab" title="Network Security"><span><i className="flaticon flaticon-system" /> Network Security</span></Tab>
                        <Tab className="tab" title="IT Audit"><span><i className="flaticon flaticon-interaction" /> IT Audit</span></Tab>
                        <Tab className="tab" title="Blockchain Audit"><span><i className="flaticon flaticon-report" /> Blockchain Audit</span></Tab>
                      </TabList>
                      <div className="box-shadow content-tab">
                        <FadeIn>
                      
                          <TabPanel>
                            <div className="content-inner">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="grey-border text-left">
                                    <div className="ttm_single_image-wrapper">
                                      <img className="img-fluid grey-boder" src="../images/Home_web_security.png" title="single-img-three" alt="single-img-three" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="text-left res-991-mt-30">
                                    <h3 className="title fs-30">We ensure the security of your organization by securing a basic website/webapp to your infrastructure and your network as well.</h3>
                                    <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">Blackbox/Whitebox testing of your Website and Webapp</span>
                                      </li>
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">Blackbox/Whitebox testing of your Mobileapp</span>
                                      </li>
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">OWASP Top-10 Security Testing</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>{/* row end */}
                            </div>{/* content-inner */}{/* row end*/}
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner active">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="text-left">
                                    <h3 className="title fs-30">A security of Network is like heart of whole security, and by making sure that there's no port open for the penetration we ensure the flawless flow of your digital heart.</h3>
                                    <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">Internal/External IP Security </span>
                                      </li>
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">Security check for level flaws and configuration loopholes</span>
                                      </li>
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">Wireless Network Security Testing</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="grey-border res-991-mt-30 text-left">
                                    <div className="ttm_single_image-wrapper">
                                      <img className="img-fluid grey-boder" src="../images/Home_Network_Security.png" title="single-img-three" alt="single-img-three" />
                                    </div>
                                  </div>
                                </div>
                              </div>{/* row end */}
                            </div>{/* content-inner */}{/* row end*/}
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="grey-border text-left">
                                    <div className="ttm_single_image-wrapper">
                                      <img className="img-fluid grey-boder" src="../images/home_IT_Audit.jpg" title="single-img-three" alt="single-img-three" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="text-left res-991-mt-30">
                                    <h3 className="title fs-30">An Infrastructure to the Governance body, the things that needs maintainance for continuous business flow, we'll be your ally to this whole journey.</h3>
                                    <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">IS & VAPT Audits for Financial Sectors </span>
                                      </li>
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">ITGS Audit for Health Domain</span>
                                      </li>
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">Firewall, Server, Compliance Audit and Patch-Up Management</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>{/* row end */}
                            </div>{/* content-inner */}{/* row end*/}
                          </TabPanel>
                          <TabPanel>
                            <div className="content-inner">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="text-left">
                                    <h3 className="title fs-30">Our journey at eNebula Solutions take a new high-way and now broading it's way by venturing the explicit & exponential Security Services.</h3>
                                    <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">An Extensive Smart Contract Audit </span>
                                      </li>
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">A complete source code review</span>
                                      </li>
                                      <li>
                                        <i className="fa fa-arrow-circle-right" />
                                        <span className="ttm-list-li-content">Fuzz Testing</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="grey-border res-991-mt-30 text-left">
                                    <div className="ttm_single_image-wrapper">
                                      <img className="img-fluid grey-boder" src="../images/home_Blockchain.jpg" title="single-img-three" alt="single-img-three" />
                                    </div>
                                  </div>
                                </div>
                              </div>{/* row end */}
                            </div>{/* content-inner */}{/* row end*/}
                          </TabPanel>
                        </FadeIn>
                      </div>
                    </Tabs>  {/* tabs end*/}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* tab section end */}
          {/* testimonial-section */}
          <section className="ttm-row only-testimonial-section pb-0 res-991-pb-0 clearfix">
            <div className="container-xl">
              <div className="row">{/* row */}
                <div className="col-lg-12">
                  {/* section title */}
                  <div className="section-title with-desc text-center clearfix">
                    <div className="title-header">
                      <h5>Testimonials</h5>
                      <h2 className="title">Take a glance at our clients <span className='font-primary'>Review</span></h2>
                    </div>
                  </div>{/* section title end */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <Slider className="testimonial-slide style1 pt-0 slick_slider res-991-pb-0" arrows={true} dots={false}>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">MP</div>
                          </div>
                        </div>
                        <blockquote>Great Audit services, i appreciate you guys taking you for your awesome job. I will recommend this Company, i will work with them in the near future!</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>mannypadron616</h6>
                          <label>Fiverr Client</label>
                        </div>
                      </div>
                    </div>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">AM</div>
                          </div>
                        </div>
                        <blockquote>Vatsal is very knowledgeable in the smart contract security field and was able to clarify a lot of things for us. He went above and beyond to deliver what was requested from him and we are thankful for his provided audition reports. I will definitely work with him again in the next project.</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>Amamda Morales </h6>
                          <label>Upwork Client</label>
                        </div>
                      </div>
                    </div>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">JK</div>
                          </div>
                        </div>
                        <blockquote>I never met a person like Sejal who is so well connected, on time and on target. Well don! I am very satisfied with your work and professionalism. Thanks again and best of luck.</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>johnkhalid449</h6>
                          <label>Fiverr Client</label>
                        </div>
                      </div>
                    </div>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">RN</div>
                          </div>
                        </div>
                        <blockquote>The guys... 27 pages with critical excellence... Got all of our issues covered and addressed with solutions. Dont look for another office..... When it comes to Blockchain these deliver!!!</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>rav4noffice</h6>
                          <label>Fiverr Client</label>
                        </div>
                      </div>
                    </div>

                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">RN</div>
                          </div>
                        </div>
                        <blockquote>The guys knows exactly what they're doing and masters at security code review. We thank SV Auditors for being neutral and transparent with us about any bugs and audit errors in our code, and from that point been able to move out to the public with the safest chain that can be! :)</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>rav4noffice</h6>
                          <label>Fiverr Client</label>
                        </div>
                      </div>
                    </div>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">AC</div>
                          </div>
                        </div>
                        <blockquote>Audited smart contract code as promised. very clear and concise, dont waste time, just contact these guys</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>acerlc</h6>
                          <label>Fiverr Client</label>
                        </div>
                      </div>
                    </div>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">NR</div>
                          </div>
                        </div>
                        <blockquote>Really profesionals on what they do , got the full report really quick and detailed report . I will definitely work again with this guys . GOOD WORK . They deffinitely deserve more exposure on crypto industry .</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>nazifrika</h6>
                          <label>Fiverr Client</label>
                        </div>
                      </div>
                    </div>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">MT</div>
                          </div>
                        </div>
                        <blockquote>cream of the crop team, detail oriented. easy to work work. PROFESSIONAL, which i really appreciate.</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>mattarad</h6>
                          <label>Fiverr Client</label>
                        </div>
                      </div>
                    </div>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">SU</div>
                          </div>
                        </div>
                        <blockquote>These guys are amazing, they are Experts in the Blockchain field, they provided me the most authentic Report, they are very good at Communication, I am sure will order more and more. They are Really Very Nice</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>sudarshanjn_7</h6>
                          <label>Fiverr Client</label>
                        </div>
                      </div>
                    </div>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">ML</div>
                          </div>
                        </div>
                        <blockquote>Ordered last minute and still accepted my order professionally. Very true to his words and service is spectacular. Couldn't ask for more. Will definitely be purchasing again if there's any future projects. Thanks again!</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>memelottery</h6>
                          <label>Fiverr Client</label>
                        </div>
                      </div>
                    </div>
                    <div className="testimonials text-center">
                      <div className="testimonial-content">{/* testimonials-content */}
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">{/* testimonials-img */}
                            <div className="center-horizontally" id="profileImage">TZ</div>
                          </div>
                        </div>
                        <blockquote>It's was a great experience to work with them, moreover when there is plenty of scammers in Blockchain business. Audit and feedbacks are very complete. I do recommend.</blockquote>
                        <div className="ttm-ratting-star">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                        <div className="testimonial-caption">{/* testimonials-caption */}
                          <h6>teambuyzooka</h6>
                          <label>Fiverr Client</label>
                        </div>
                      </div>
                    </div>


                  </Slider>
                </div>
              </div>
            </div>
          </section>
          {/* testimonial-section end */}
          {/* first-row-title-section */}
          {/* <section className="bg-img-video ttm-row second-row-title-section mt_90 ttm-bg ttm-bgimage-yes clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    <div className="text-center">
                      <div className="ttm-play-icon-btn mb-5">
                        <div className="ttm-play-icon-animation">
                          <a id="playbutton">
                            <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-sm rounded-circle">
                              <i className="fa fa-play" />
                            </div>
                          </a>
                          <div id="videobox">
                            <i id="close-btn" className="fa fa-times"></i>
                            <div id="video-wrapper">
                              <iframe width="500" height="500" src="https://www.youtube.com/embed/7e90gBu4pas" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>                              
                            </div>
                          </div> 
                        </div>
                      </div>
                      <div className="section-title with-desc text-center clearfix">
                        <div className="title-header">
                          <h2 className="title">We Provide Outsourced IT Services For Small &amp; <span className="ttm-textcolor-white">Mid-Sized Business</span></h2>
                        </div>
                        <div className="title-desc">Appropriate for your specific business, making it easy for<br /> you to have quality IT services. What We Do</div>
                      </div>
                      <div className="mt-5">
                        <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white">More Services</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          {/* first-row-title-section end*/}
          {/* portfolio-section */}
         {/* hidden at portfolio-hidden.txt */}
          {/* portfolio-section end */}
          {/* our-partner-section */}
          {/* <section className="bg-img-clients ttm-row our-partner-section mt-2 mb-2 mt_91 res-991-mt-0 res-991-p-0 clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-12 text-center">
                    <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={5}>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                          <div className="client">
                            <div className="ttm-client-logo-tooltip" data-tooltip="ACED">
                              <img className="img-fluid" src="../images/clients/1.png" alt="image" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                          <div className="client">
                            <div className="ttm-client-logo-tooltip" data-tooltip="BlackHole Protocol">
                              <img className="img-fluid" src="../images/clients/2.png" alt="image" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                          <div className="client">
                            <div className="ttm-client-logo-tooltip" data-tooltip="BNBeer">
                              <img className="img-fluid" src="../images/clients/3.png" alt="image" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                          <div className="client">
                            <div className="ttm-client-logo-tooltip" data-tooltip="Duke Coin">
                              <img className="img-fluid" src="../images/clients/4.png" alt="image" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                          <div className="client">
                            <div className="ttm-client-logo-tooltip" data-tooltip="Education Ecosystem">
                              <img className="img-fluid" src="../images/clients/5.png" alt="image" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                          <div className="client">
                            <div className="ttm-client-logo-tooltip" data-tooltip="Litherium">
                              <img className="img-fluid" src="../images/clients/6.png" alt="image" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                          <div className="client">
                            <div className="ttm-client-logo-tooltip" data-tooltip="Wojak">
                              <img className="img-fluid" src="../images/clients/7.png" alt="image" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </section> */}
          {/* our-partner-section end */}
        </section>
      </div>
    )
  }
}


export default Home;
