import React, { Component } from 'react';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import Header from '../layout/Header';

export class Aboutus_02 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [{

        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {

        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {

        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    };
    return (
      <div className="site-main">
        <Header active="" />
        {/* page-title */}
        <section className="main-background">
          <div className="ttm-page-title-row">
            <div className="container-xl">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-box text-center">
                    <div className="page-title-heading">
                      <h1 className="title">About Us</h1>
                    </div>{/* /.page-title-captions */}
                    <div className="breadcrumb-wrapper">
                      <span>
                        <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                      </span>
                      <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                      <span className=''>About Us</span>
                    </div>
                  </div>
                </div>{/* /.col-md-12 */}
              </div>{/* /.row */}
            </div>{/* /.container */}
          </div>
          {/* page-title end*/}
          {/* aboutus-section */}
          <section className="ttm-row clearfix">
            <div className="container-xl">
              <div className="row">{/* row */}
                <div className="col-lg-6">
                  <div className="position-relative">
                    {/* ttm_single_image-wrapper */}
                    <div className="ttm_single_image-wrapper text-sm-center mr-35 res-991-m-0">
                      <img className="img-fluid" src="../images/about_us.png" title="single-img-nine" alt="single-img-nine" />
                    </div>{/* ttm_single_image-wrapper end */}
                    <div className="ttm-highlight-fid-style-2">
                      {/*ttm-fid*/}
                      <div className="ttm-fid inside without-icon">
                        <div className="ttm-fid-contents">
                          <h4 className="ttm-fid-inner font-dark">
                            <CountUp start={0} end={250} duration={5} />+
                          </h4>
                          <h3 className="ttm-fid-title font-dark">Successully Projects </h3>
                        </div>
                      </div>{/* ttm-fid end*/}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="res-991-mt-30">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>About us</h5>
                        <h2 className="title">We're Allured with the Idea of being <span className='font-primary'>Absolute</span></h2>
                      </div>
                      <div className="title-desc">
                        <p>Virtual Caim is an IT Security Company for providing solutions and consultation on Web2, Web3 and even whole IT System as well as Network infrastructure to all types of Security Issues starting from basic Networking Issues, System related issues, IT infrastructure issues to the websites, web applications, mobile applications security along with the futuristic techs like Blockchain Application Security (dApps, DAO, Oracles, etc.) including source code review. At Virtual Caim, we are providing Information and Security consultancy for our clients from various countries along with performing Audit Projects for our Global & Local clients.</p>
                      </div>
                    </div>{/* section title end */}
                    {/* row */}
                     {/* separator */}
                     <div className="separator">
                      <div className="sep-line mt-4 mb-3" />
                    </div>
                    {/* separator */}
                    <div className="row mt-15">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style3 left-icon icon-align-top">
                          <div className="featured-icon">{/* featured-icon */}
                            <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                              <img className='img-fluid' src="../images/icons/our_mission.svg" />
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">{/* featured title */}
                              <h5>Our Mission</h5>
                            </div>
                            <div className="featured-desc">{/* featured desc */}
                              <p>Our Mission to make a Unit Security firm which is well-focused on the real security issues from the very starting, so to accomplish our  mission we’re mainly focused on the providing security to all the domains by providing pre-audits, term base audits, internal & external testings, red teaming, etc. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-3" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-12">
                        {/* featured-icon-box */}
                        <div className="featured-icon-box style3 left-icon icon-align-top">
                          <div className="featured-icon">{/* featured-icon */}
                            <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                              <img className='img-fluid' src="../images/icons/our_vision.svg" />
                            </div>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">{/* featured title */}
                              <h5>Our Vision</h5>
                            </div>
                            <div className="featured-desc">{/* featured desc */}
                              <p>Our Vision is to fortunate the Virtual Caim(A protection of Circle) for every IT startups and big firms out there that it becomes a brand for the virtual security.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* row end */}
            </div>
          </section>
          {/* aboutus-section end */}
          {/* team-section */}
          <section className="ttm-row team-section clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-md-12">
                  {/* section title */}
                  <div className="section-title text-center with-desc clearfix">
                    <div className="title-header">
                      <h5>Our Team</h5>
                      <h2 className="title">Meet our <span className='font-primary'>Leadership</span></h2>
                    </div>
                  </div>{/* section title end */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-4 col-sm-6">
                  {/* featured-imagebox-team */}
                  <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                      {/* <img className="img-fluid" src="../images/Sejal.png" alt="image" /> */}
                      <img className="img-fluid" src="https://img.freepik.com/premium-vector/vector-flat-icon-confident-woman-stylish-suit-with-vibrant-red-tie_176841-6900.jpg?w=740" alt="image" />
                      <div className="ttm-social-links-wrapper">
                        <ul className="social-icons list-inline">{/* social-icons */}
                          <a href='https://www.linkedin.com/in/sejal-barad/' target='_blank'><li className="social-linkedin"><i className="fab fa-linkedin-in font-dark" /></li></a>
                        </ul>
                      </div>
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">{/* featured-title */}
                        <h5 className='font-dark'>Sejal Barad</h5>
                      </div>
                      <p className="category font-dark">Chief Executive Officer</p>{/* category */}
                    </div>
                  </div>{/* featured-imagebox-team end*/}
                </div>
                <div className="col-md-6 col-lg-4 col-sm-6">
                  {/* featured-imagebox-team */}
                  <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                      {/* <img className="img-fluid" src="../images/Vatsal.png" alt="image" /> */}
                      <img className="img-fluid" src="https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=740" alt="image" />
                      <div className="ttm-social-links-wrapper">
                        <ul className="social-icons list-inline">{/* social-icons */}
                          <a href='https://www.linkedin.com/in/vatsal-raychura/' target='_blank'><li className="social-linkedin"><i className="fab fa-linkedin-in  font-dark" /></li></a>
                        </ul>
                      </div>
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">{/* featured-title */}
                        <h5 className='font-dark'>Vatsal Raichura</h5>
                      </div>
                      <p className="category font-dark"> Managing Director</p>{/* category */}
                    </div>
                  </div>{/* featured-imagebox-team end*/}
                </div>
                <div className="col-md-6 col-lg-4 col-sm-6">
                  {/* featured-imagebox-team */}
                  <div className="featured-imagebox featured-imagebox-team style1 mb-30">
                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                      {/* <img className="img-fluid" src="../images/Bhagirath.png" alt="image" /> */}
                      <img className="img-fluid" src="https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=740" alt="image" />
                      <div className="ttm-social-links-wrapper">
                        <ul className="social-icons list-inline">{/* social-icons */}
                        <a href='https://www.linkedin.com/in/adarsh-desai-5263141a5' target='_blank'><li className="social-linkedin"><i className="fab fa-linkedin-in font-dark" /></li></a>
                        </ul>
                      </div>
                    </div>
                    <div className="featured-content box-shadow">
                      <div className="featured-title">{/* featured-title */}
                        <h5 className='font-dark'>Adarsh Desai</h5>
                      </div>
                      <p className="category font-dark">Chief Information Officer</p>{/* category */}
                    </div>
                  </div>{/* featured-imagebox-team end*/}
                </div>
              </div>
            </div>
          </section>
          {/* team-section end*/}
          {/* our-partner-section */}
          {/* <section className="bg-img-clients ttm-row our-partner-section mt_91 res-991-mt-0 res-991-p-0 clearfix">
            <div className="container-xl">
              <div className="row">
                <div className="col-12 text-center">
                  <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={5}>
                    <div className="ttm-box-col-wrapper">
                      <div className="client-box ttm-box-view-boxed-logo">
                        <div className="client">
                          <div className="ttm-client-logo-tooltip" data-tooltip="ACED">
                            <img className="img-fluid" src="../images/clients/1.png" alt="image" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-box-col-wrapper">
                      <div className="client-box ttm-box-view-boxed-logo">
                        <div className="client">
                          <div className="ttm-client-logo-tooltip" data-tooltip="BlackHole Protocol">
                            <img className="img-fluid" src="../images/clients/2.png" alt="image" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-box-col-wrapper">
                      <div className="client-box ttm-box-view-boxed-logo">
                        <div className="client">
                          <div className="ttm-client-logo-tooltip" data-tooltip="BNBeer">
                            <img className="img-fluid" src="../images/clients/3.png" alt="image" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-box-col-wrapper">
                      <div className="client-box ttm-box-view-boxed-logo">
                        <div className="client">
                          <div className="ttm-client-logo-tooltip" data-tooltip="Duke Coin">
                            <img className="img-fluid" src="../images/clients/4.png" alt="image" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-box-col-wrapper">
                      <div className="client-box ttm-box-view-boxed-logo">
                        <div className="client">
                          <div className="ttm-client-logo-tooltip" data-tooltip="Education Ecosystem">
                            <img className="img-fluid" src="../images/clients/5.png" alt="image" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-box-col-wrapper">
                      <div className="client-box ttm-box-view-boxed-logo">
                        <div className="client">
                          <div className="ttm-client-logo-tooltip" data-tooltip="Litherium">
                            <img className="img-fluid" src="../images/clients/6.png" alt="image" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ttm-box-col-wrapper">
                      <div className="client-box ttm-box-view-boxed-logo">
                        <div className="client">
                          <div className="ttm-client-logo-tooltip" data-tooltip="Wojak">
                            <img className="img-fluid" src="../images/clients/7.png" alt="image" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </section> */}
          {/* our-partner-section end */}
        </section>
      </div>
    )
  }
}


export default Aboutus_02;