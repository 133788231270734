import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer, MDBRow } from
"mdbreact";
import { Link } from "react-router-dom";

export class CarouselPage extends Component {
    render() {
        return (
            <MDBContainer fluid className="backgroud">
            <MDBRow>
            <MDBCarousel
            activeItem={1}
            length={1}
            showControls={false}
            showIndicators={false}
            id="Home_banner"
            >
            <MDBCarouselInner>
                <MDBCarouselItem itemId="1">
                <MDBView>
                <video className="d-block w-100 z-index-999" type="video/mp4" src="/video/banner-1.mp4" autoPlay loop muted />
                {/* <video className="mob-show d-block w-100 z-index-999" type="https://via.placeholder.com/1920X850/888888.jpg" autoPlay loop muted /> */}
                    {/* <img className="d-block w-100" src="https://via.placeholder.com/1920X850/888888.jpg" alt="First slide" /> */}
                </MDBView>
                <MDBCarouselCaption>
                    <div className="container-xl">
                        <div className="carousel-caption-inner banner1">
                            <MDBAnimation className="slideInUp" count={1} delay="0.5s" >
                                <h2 className="ttm-textcolor-darkgrey flipInY">A Virtual Caim to secure your all Digital Notion - <br/><span className="font-primary">Do your own Due Dilligence.</span></h2>
                            </MDBAnimation>
                            <MDBAnimation className="slideInUp" count={1} delay="0.6s" >
                            <p className="text">We are equipped with an updated technical knowledge to serve our customers properly. Our method of application maintains the industry.</p>
                            </MDBAnimation>
                            <MDBAnimation className="slideInUp" count={1} delay="0.7s" >
                            <p>
                                <a target="_blank" href='/contact_us'> <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white">Discuss Your Project </button></a>&nbsp; &nbsp;
                                <a target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLScoG4Q2QPQ1h1ke-3YFhixCfP9q4s_TBvZ_xN6Ldgj6oQ6oIw/viewform?usp=sf_link'> <button className="ttm-btn btn-inline ttm-btn-size-md ttm-textcolor-darkgrey ml-3"><i className="ti-arrow-circle-right" />get a Quote</button></a>
                            </p>                            
                            </MDBAnimation>
                        </div>
                    </div>
                </MDBCarouselCaption>
                </MDBCarouselItem>
                {/* <MDBCarouselItem itemId="2">
                <MDBCarouselCaption>
                    <div className="container-xl">
                        <div className="carousel-caption-inner banner2">
                            <MDBAnimation className="slideInUp" count={1} delay="5s" >
                                <h2 className="ttm-textcolor-darkgrey">Real-Time Monitoring Your <strong className="ttm-textcolor-skincolor">Infrstracture</strong></h2>
                            </MDBAnimation>
                            <MDBAnimation className="slideInUp" count={1} delay="5.5s" >
                                <p className="text">We are equipped with an updated technical knowledge to serve our customers<br /> properly. Our method of application maintains the industry.</p>
                            </MDBAnimation>
                            <MDBAnimation className="slideInUp" count={1} delay="6s" >
                                <p>
                                    <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white mr-3">read more</button>
                                    <button className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white">get altech</button>
                                </p>
                          </MDBAnimation>
                        </div>
                      </div>
                </MDBCarouselCaption>
                </MDBCarouselItem> */}
            </MDBCarouselInner>
            </MDBCarousel>
            </MDBRow>
            </MDBContainer>
        );
    }
}

export default CarouselPage;