import React, { Component } from 'react';
import {
  MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
  MDBHamburgerToggler
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';

class Mobilemenu extends Component {
  state = {
    collapse1: false,
    collapseID: ''
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
  }

  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  }

  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler id="hamburger1" onClick={() => this.toggleSingleCollapse('collapse1')} />
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            <MDBNavbarNav left>
              <MDBNavItem active>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/'}>Home</MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/services'}>Services</MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/portfolio'}>Portfolio</MDBDropdownItem>
              </MDBNavItem>
              {/* <MDBNavItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/faq'}>FAQs</MDBDropdownItem>
              </MDBNavItem> */}
              <MDBNavItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/about_us'}>About Us</MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/contact_us'}>Contact Us</MDBDropdownItem>
              </MDBNavItem>
              </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobilemenu;