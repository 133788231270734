import React, { Component } from 'react';
import Header from '../layout/Header';
import ContactUsForm from '../pages/contact_us_form';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export class ContactUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <section className="main-background">
                    <div className="ttm-page-title-row">
                        <div className="container-xl">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title-box text-center">
                                        <div className="page-title-heading">
                                            <h1 className="title">Contact Us</h1>
                                        </div>{/* /.page-title-captions */}
                                        <div className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                                            </span>
                                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                                            <span>Contact Us</span>
                                        </div>
                                    </div>
                                </div>{/* /.col-md-12 */}
                            </div>{/* /.row */}
                        </div>{/* /.container */}
                    </div>
                    {/* page-title end*/}
                    <section className="ttm-row contact-section clearfix">
                        <div className="container-xl">
                            <div className="row">{/* row */}
                                <div className="col-lg-8 offset-lg-2">
                                    {/* section-title */}
                                    <div className="section-title with-desc text-center clearfix">
                                        <div className="title-header">
                                            <h2 className="title">Drop us a line</h2>
                                        </div>
                                    </div>{/* section-title end */}
                                </div>
                            </div>
                            {/* row end */}
                            {/* row */}
                            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSflm0ofxu1gX8eUtwHKxThqGomg4rhu7qfRAowkR0HnZiYCkA/viewform?embedded=true" width="750" height="920" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
                            <div className="row">{/* row */}
                                <div className="col-lg-12">
                                    <ContactUsForm />
                                </div>
                            </div>
                            {/* row end*/}
                        </div>
                    </section>
                    {/* contact-box-section */}
                    <div className="ttm-row contact-box-section clearfix">
                        <div className="container-xl">
                            <div className="row">
                                <div className="col-lg-4 res-991-mb-60 res-991-mt-30">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box style8 text-center box-shadow">
                                        <div className="featured-icon">{/* featured-icon*/}
                                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md rounded-circle">
                                                <i className="far fa-envelope" />
                                            </div>
                                        </div>{/* featured-icon */}
                                        <div className="featured-content">{/* featured-content */}
                                            <div className="featured-title">
                                                <h5 className='font-dark'>Email</h5>{/* featured-title */}
                                            </div>
                                            <div className="featured-desc">{/* featured-description */}
                                                <p>
                                                    <a href="mailto:audit@virtualcaim.com" className='font-dark'>audit{"<at>"}virtualcaim{"<dot>"}com</a><br />
                                                </p>
                                            </div>
                                        </div>{/* featured-content END*/}
                                    </div>{/* featured-icon-box */}
                                </div>
                                <div className="col-lg-4 res-991-mb-60">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box style8 text-center box-shadow">
                                        <div className="featured-icon">{/* featured-icon*/}
                                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md rounded-circle">
                                                <i className="fa fa-phone" />
                                            </div>
                                        </div>{/* featured-icon */}
                                        <div className="featured-content">{/* featured-content */}
                                            <div className="featured-title">
                                                <h5 className='font-dark'>Phone</h5>{/* featured-title */}
                                            </div>
                                            <div className="featured-desc">{/* featured-description */}
                                                <p className='font-dark'>
                                                    +91 8320295470<br />
                                                </p>
                                            </div>
                                        </div>{/* featured-content END*/}
                                    </div>{/* featured-icon-box */}
                                </div>
                                <div className="col-lg-4">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box style8 text-center box-shadow">
                                        <div className="featured-icon">{/* featured-icon*/}
                                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md rounded-circle">
                                                <i className="ti ti-target" />
                                            </div>
                                        </div>{/* featured-icon */}
                                        <div className="featured-content">{/* featured-content */}
                                            <div className="featured-title">
                                                <h5 className='font-dark'>Address</h5>{/* featured-title */}
                                            </div>
                                            <div className="featured-desc">{/* featured-description */}
                                                <p className='font-dark'>
                                                Veraval, Gujarat, India<br />
                                                </p>
                                            </div>
                                        </div>{/* featured-content END*/}
                                    </div>{/* featured-icon-box */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* contact-box-section end */}
                    {/* map-section */}
                    <section className="ttm-row map-section clearfix">
                        <div className="map-wrapper">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29814.980177703277!2d70.35915014999999!3d20.917447799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bfd32308996ab5b%3A0xf285471b09bfa777!2sVeraval%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1692761441446!5m2!1sen!2sin" height="100%" loading="lazy" />
                        </div>
                    </section>
                    {/* map-section end */}
                </section>
            </div>
        )
    }
}


export default ContactUs;
