import React, { Component } from 'react';
import Header from '../layout/Header';

export class Services extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <section className="main-background">
                    <div className="ttm-page-title-row">
                        <div className="container-xl">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title-box text-center">
                                        <div className="page-title-heading">
                                            <h1 className="title">Our Services</h1>
                                        </div>{/* /.page-title-captions */}
                                        <div className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                                            </span>
                                            <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                                            <span>Services</span>
                                        </div>
                                    </div>
                                </div>{/* /.col-md-12 */}
                            </div>{/* /.row */}
                        </div>{/* /.container */}
                    </div>
                    {/* page-title end*/}
                    <section className="ttm-row grid-section clearfix">
                        <div className="container-xl">
                            <div className="row">{/* row */}
                                <div className="col-lg-8 offset-lg-2">
                                    {/* section-title */}
                                    <div className="section-title with-desc text-center clearfix">
                                        <div className="title-header">
                                            <h5>Top-Notch Services</h5>
                                            <h2 className="title">Maintaining the Industry's High Standard for Small Startups to <span className='font-primary'>Big Giants</span></h2>
                                        </div>
                                    </div>{/* section-title end */}
                                </div>
                            </div>
                            {/* row end */}
                            {/* row */}
                            <div className="row">
                               
                                <div className="col-md-6 col-lg-6">
                                    {/* featured-imagebox-services */}
                                    {/* featured-imagebox-services */}
                                    <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                                        <div className="featured-thumbnail">{/* featured-thumbnail */}
                                            <img className="img-fluid w-100" src="../images/Home_web_security.png" alt="image" />
                                        </div>
                                        <div className="featured-content box-shadow">
                                            <div className="featured-title">{/* featured-title */}
                                                <h5 className='font-dark'>Web/Mobile Security</h5>
                                            </div>
                                            <div className="featured-desc">{/* featured-title */}
                                                <p className='font-dark'>Web and Mobile application security testing may be a broad method that features a large number of processes that modify the safety testing of an online application. it's a organized method that starts from distinguishing and scoping the complete application, followed by coming up with multiple tests. In general, the complete security testing method is followed by a format report that has the known vulnerabilities, attainable threats and suggestions for overcoming the safety shortfalls.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* featured-imagebox-services */}
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {/* featured-imagebox-services */}
                                    <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                                        <div className="featured-thumbnail">{/* featured-thumbnail */}
                                            <img className="img-fluid w-100" src="../images/Home_Network_Security.png" alt="image" />
                                        </div>
                                        <div className="featured-content box-shadow">
                                            <div className="featured-title">{/* featured-title */}
                                                <h5 className='font-dark'>Network Security</h5>
                                            </div>
                                            <div className="featured-desc">{/* featured-title */}
                                                <p className='font-dark'>Network security testing may be a broad means that of testing security controls across a network to spot and demonstrate vulnerabilities & verify risks. whereas the testing medium will amendment (wireless, ethernet, etc.), with lastly concerned about the information or systems. The goals of testing fluctuate counting on the objective however conjointly the organization's maturity. Network testing will facilitate authenticate security defenses, meet compliance mandates & check the Security controls of any kind of electronic information.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* featured-imagebox-services */}
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {/* featured-imagebox-services */}
                                    <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                                        <div className="featured-thumbnail">{/* featured-thumbnail */}
                                            <img className="img-fluid w-100" src="../images/home_IT_Audit.jpg" alt="image" />
                                        </div>
                                        <div className="featured-content box-shadow">
                                            <div className="featured-title">{/* featured-title */}
                                                <h5 className='font-dark'>IT Audit</h5>
                                            </div>
                                            <div className="featured-desc">{/* featured-title */}
                                                <p className='font-dark'>An IT security audit could be a comprehensive examination & assessment of your organization’s data security system. Conducting regular audits will assist you determine weak spots & vulnerabilities in your whole IT infrastructure, along with verifying your security controls, guarantee restrictive compliance, and more. IT security auditing is most effective if it conducted on continuous time period. Plan a schedule to occasionally audit your entire system portfolio to assess your compliance with data regulations & look after your operative inclination for cyberattacks.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* featured-imagebox-services */}
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {/* featured-imagebox-services */}
                                    <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                                        <div className="featured-thumbnail">{/* featured-thumbnail */}
                                            <img className="img-fluid w-100" src="../images/home_Blockchain.jpg" alt="image" />
                                        </div>
                                        <div className="featured-content box-shadow">
                                            <div className="featured-title">{/* featured-title */}
                                                <h5 className='font-dark'>Blockchain Audit</h5>
                                            </div>
                                            <div className="featured-desc">{/* featured-title */}
                                                <p className='font-dark'>Even though the data of integrity is safeguarded by the blockchain system, Applications running on it aren’t insusceptible to attacks. And the fact that securing blockchain applications from an attack is a quite challenging and time-consuming process is even more captivating. That is why the engagement of Blockchain as software in the companies is being reason behind the notion of executing blockchain security audit is gaining prominence in industries.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* row end*/}
                        </div>
                    </section>
                </section>
            </div>
        )
    }
}


export default Services;