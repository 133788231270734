import React, { Component } from 'react';
import { fadeIn } from 'react-animations'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import Header from '../layout/Header';

export class Faq extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <section className="main-background">
                    <div className="ttm-page-title-row">
                        <div className="container-xl">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title-box text-center">
                                        <div className="page-title-heading">
                                            <h1 className="title ">FAQs</h1>
                                        </div>{/* /.page-title-captions */}
                                        <div className="breadcrumb-wrapper">
                                            <span>
                                                <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home " />&nbsp;&nbsp;Home</a>
                                            </span>
                                            <span className="ttm-bread-sep ">&nbsp; : : &nbsp;</span>
                                            <span className=' '>FAQs</span>
                                        </div>
                                    </div>
                                </div>{/* /.col-md-12 */}
                            </div>{/* /.row */}
                        </div>{/* /.container */}
                    </div>
                    {/* page-title end*/}
                    <section className="ttm-row faqs-section clearfix">
                        <div className="container-xl">
                            <div className="row">{/* row */}
                                <div className="col-lg-8 offset-lg-2">
                                    {/* section-title */}
                                    <div className="section-title with-desc text-center clearfix">
                                        <div className="title-header">
                                            <h5 className=''>Do you have any questions?</h5>
                                            <h2 className="title ">FAQ's for Smart Contract Audit</h2>
                                        </div>
                                    </div>{/* section-title end */}
                                </div>
                            </div>
                            {/* row end */}
                            {/* row */}
                            <div className="row">
                                {/* <div className="col-lg-6">
                        <div className="ttm_single_image-wrapper">
                            <img className="img-fluid" src="https://via.placeholder.com/1200X800/444444.jpg" title="single-img-eleven" alt="single-img-eleven" />
                        </div>
                        <div className="ttm-play-icon-btn ttm-play-icon-btn-center">
                            <div className="ttm-play-icon-animation"> 
                                <a id="playbutton">
                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-sm rounded-circle">
                                        <i className="ti ti-control-play" />
                                    </div>
                                </a>
                                <div id="videobox">
                                    <i id="close-btn" className="fa fa-times"></i>
                                    <div id="video-wrapper">
                                        <iframe width="500" height="500" src="https://www.youtube.com/embed/7e90gBu4pas" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div> */}
                                <div className="col-lg-12 res-991-mt-30">
                                    {/* accordian */}
                                    <Accordion>
                                        <AccordionItem key='1'>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is a Smart Contract Audit?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <h5 className='line-h-35'>
                                                    Smart Contract Audit is a comprehensive process to scrutinize a smart contract’s code that is used to interact with a cryptocurrency or blockchain. This process is carried out to discover errors, issues & security vulnerabilities in the code in order to rectify & fix them. It secures the code from potential future bugs.
                                                </h5>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Who needs a Smart Contract Audit?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <h5 className='line-h-35'>
                                                    From an ICO, STO, fintech, Games or any Dapp solution based on Smart Contracts you should always go for third party Audit.
                                                </h5>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is the time scale for an audit to complete?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <h5 className='line-h-35'>
                                                    The time duration of an audit is purely depending on the code size & the complexity of the project. A standard token (ERC20 & other ERC standards) can be audited within a week, on the other hand, complex DeFi projects, Blockchain wallets/dApps may extend around a couple of weeks to a month.
                                                </h5>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is our Audit process?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <h5 className='line-h-35'>
                                                    Our audit process is quite simple and straightforward in general,
                                                    <br />1) We collect all the basic information regarding the project and mainly smart contract.
                                                    <br />2) We will take a quick overview of smart contracts before finalizing the budget and timeline.
                                                    <br />3) After the confirmation of the project from the client-side, we'll first start the manual audit by reviewing the code line by line.
                                                    <br />4) Once the manual review is completed, we'll start the tools-based security analysis, and if there are issues found with good confidence, we'll cross-check and verify it manually again.
                                                    <br />5) Report making of all the findings.
                                                    <br />6) Delivery of Report (Primary report for resolving/discussing the issue with the client)
                                                    <br />7) Re-Audit of updated code.
                                                    <br />8) Final Report delivery with the resolved or acknowledged comments of the client.
                                                    Of course, in special cases do separate testing as per the client's demand (i.e., fuzz testing). also in some cases, we provide add-on services for the client.
                                                </h5>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How much does a smart contract audit cost?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <h5 className='line-h-35'>
                                                    The price of audit is depending upon the complexity of the code and lines of number in the code.
                                                </h5>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Which tools we are using?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <h5 className='line-h-35'>
                                                    MythX, Mythril, Slither, Solhint, Remix, VS-Code-Solidity-Auditor, Solidity Static Analysis, Surya, Manticore, Truffle, Ganache, Ubuntu, etc.
                                                </h5>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    For Fuzz Testing
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <h5 className='line-h-35'>
                                                    sFuzz, echidna, Harvey, etc.
                                                </h5>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                            {/* row end*/}
                        </div>
                    </section>
                </section>
            </div>
        )
    }
}


export default Faq;